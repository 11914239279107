import { createBrowserRouter, Navigate } from "react-router-dom";
import Landing from "./landing/landing";
import Contact from "./contact/contact";
import Training from "./training/training";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "training",
    element: <Training />,
  },
  {
    path: "contacto",
    element: <Contact />,
  },
  {
    path: "*",
    element: <Navigate to="/training" />,
  },
]);

export default router;
