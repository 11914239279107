import LoaderVideo from "../../assets/loader.mp4";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader">
      <video muted autoPlay loop playsInline>
        <source src={LoaderVideo} type="video/mp4"></source>
      </video>
    </div>
  );
};

export default Loader;
