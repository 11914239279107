import Iframe from "react-iframe";
import "./training.css";
import { useState } from "react";
import Loader from "../../components/loader/loader";

function Training() {
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState("0%");

  const handleLoad = () => {
    setHeight("100%");
    setLoading(false);
  };

  return (
    <div className="training">
      {loading ? <Loader /> : null}
      <Iframe
        url="https://subscribepage.io/t6iOFp"
        width="100%"
        height={height}
        id=""
        loading="eager"
        className=""
        display="block"
        position="absolute"
        onLoad={handleLoad}
      />
    </div>
  );
}

export default Training;
