import { useNavigate } from "react-router-dom";

import Logo from "../../assets/logo32.png";
import Logo46 from "../../assets/logo46.png";
import Profile from "../../assets/landing/profile.png";
import { ReactComponent as Branch } from "../../assets/branch.svg";

import "./landing.css";

const Landing = () => {
  const navigate = useNavigate();

  return (
    <main className="main-landing">
      <section className="section-one">
        <div className="content">
          <h1 className="title">MUJER AUTÉNTICA Y PLENA</h1>
          <p className="description">
            Eleva tu bienestar para empezar a vivir una vida soñada, retomando
            el poder sobre tus emociones, a través de mi programa MAP, Mujer
            auténtica y plena.
          </p>
          <img className="logo" src={Logo} alt="Ross Ruidiaz" />
        </div>
      </section>
      <section className="section-two">
        <Branch className="branch" />
        <div className="content">
          <div className="profile-content">
            <img className="profile" src={Profile} alt="Mi Historia" />
            <img className="logo" src={Logo46} alt="Ross Ruidiaz" />
          </div>

          <div className="history-content">
            <h2 className="title">Mi Historía</h2>
            <span className="item">Bioingeniera</span>
            <br />
            <span className="item">
              Coach cristiana certificada por MétodoCC.
            </span>
            <br />
            <span className="item">Psicóloga en formación.</span>
            <br />
            <span className="item">
              Líder, maestra y consejera durante 5 años, con experiencia
              acompañando a más de 50 personas.
            </span>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Landing;
